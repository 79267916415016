/*
Template Name: Groci - Organic Food & Grocery Market Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.1
*/
function singlePage(){
  var objowlcarousel = $(".owl-carousel-featured");
  if (objowlcarousel.length > 0) {
    objowlcarousel.owlCarousel({
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
          items: 5,
        },
        1200: {
          items: 5,
        },
      },
      lazyLoad: true,
      pagination: false,
      loop: true,
      dots: false,
      autoPlay: false,
      navigation: true,
      stopOnHover: true,
      nav: true,
      navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"]
    });
  }
  // ===========Single Items Slider============
  var sync1 = $("#sync1");
  var sync2 = $("#sync2");
  sync1.owlCarousel({
    singleItem: true,
    items: 1,
    slideSpeed: 1000,
    pagination: false,
    navigation: true,
    autoPlay: 2500,
    dots: false,
    nav: true,
    navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    afterAction: syncPosition,
    responsiveRefreshRate: 200,
  });
  sync2.owlCarousel({
    items: 5,
    navigation: true,
    dots: false,
    pagination: false,
    nav: true,
    navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveRefreshRate: 100,
    afterInit: function(el) {
      el.find(".owl-item").eq(0).addClass("synced");
    }
  });

  function syncPosition(el) {
    var current = this.currentItem;
    $("#sync2")
        .find(".owl-item")
        .removeClass("synced")
        .eq(current)
        .addClass("synced")
    if ($("#sync2").data("owlCarousel") !== undefined) {
      center(current)
    }
  }
  $("#sync2").on("click", ".owl-item", function(e) {
    e.preventDefault();
    var number = $(this).data("owlItem");
    sync1.trigger("owl.goTo", number);
  });

  function center(number) {
    var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
    var num = number;
    var found = false;
    for (var i in sync2visible) {
      if (num === sync2visible[i]) {
        var found = true;
      }
    }
    if (found === false) {
      if (num > sync2visible[sync2visible.length - 1]) {
        sync2.trigger("owl.goTo", num - sync2visible.length + 2)
      } else {
        if (num - 1 === -1) {
          num = 0;
        }
        sync2.trigger("owl.goTo", num);
      }
    } else if (num === sync2visible[sync2visible.length - 1]) {
      sync2.trigger("owl.goTo", sync2visible[1])
    } else if (num === sync2visible[0]) {
      sync2.trigger("owl.goTo", num - 1)
    }
  }
}
function shopPage(){
  var objowlcarousel = $(".owl-carousel-featured");
  if (objowlcarousel.length > 0) {
    objowlcarousel.owlCarousel({
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
          items: 5,
        },
        1200: {
          items: 5,
        },
      },
      lazyLoad: true,
      pagination: false,
      loop: true,
      dots: false,
      autoPlay: false,
      navigation: true,
      stopOnHover: true,
      nav: true,
      navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"]
    });
  }
}
function loadingFrame(){

}
var fetchUrl={}
function setPath(idClass, path){
  fetchUrl[idClass] = path
}
function getPath(idClass){
  return fetchUrl[idClass]
}
function changePath(idClass,furl){
  fetchUrl[idClass]=furl
}
function InitSelect(idClass,furl){
  function formatRepoSelection (repo) {
    return repo.label;
  }
  $('.'+idClass+'').on('select2:select', function (e) {
    var data = e.params.data;
    var elem = $('.'+idClass+'')[0]
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("change", false, true);
    var selectionFired = new CustomEvent("change", {
      "detail": data
    });
    elem.dispatchEvent(selectionFired);
    $('.select2-results').remove();
    $('.select2-dropdown').remove();
  });
  $('.'+idClass+'').empty().select2(
      {
        templateSelection:formatRepoSelection,
        dropdownParent: $('.main-content-body'),
        ajax: {
          url: ()=> getPath(idClass),
          dataType: 'json',
          processResults: function (data) {
            var results = $.map(data, function (item) {
              return {
                id: item.id,
                label: item.label,
                name: item.name,
                text: item.label,
                value:{
                  name:item.value.name,
                  value:item.value.value,
                }
              }
            })
            return {results: results}
          }
        }
      }
  );
}
function SelectRemote(){
  $('.js-example-basic-single').select2({
    dropdownParent: $('.main-content-body'),
    ajax: {
      url: 'https://api.github.com/search/repositories',
      dataType: 'json',
      processResults: function (data) {

        var results = $.map(data.items, function (item) {
          return {
            text: item.name,
            id: item.id
          }
        })
        return {results: results}
      }
    }
  });

}
function MainSilder() {
  var mainslider = $(".owl-carousel-slider");
  if (mainslider.length > 0) {
    mainslider.owlCarousel({
      items: 1,
      dots: false,
      lazyLoad: true,
      pagination: true,
      autoPlay: 4000,
      loop: true,
      singleItem: true,
      navigation: true,
      stopOnHover: true,
      nav: true,
      navigationText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"]
    });
  }else{
    setTimeout(() => { MainSilder() }, 100);
  }
}
function CategoryTop(){
  var objowlcarousel = $(".owl-carousel-category");
  if (objowlcarousel.length > 0) {
    objowlcarousel.owlCarousel({
      responsive: {
        0: {
          items: 3,
        },
        600: {
          items: 5,
          nav: false
        },
        1000: {
          items: 8,
        },
        1200: {
          items: 8,
        },
      },
      items: 8,
      lazyLoad: true,
      pagination: false,
      loop: true,
      dots: false,
      autoPlay: 2000,
      navigation: true,
      stopOnHover: true,
      nav: true,
      navigationText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"]
    });
  }else{
    setTimeout(() => { CategoryTop() }, 100);
  }
}
function itemsSlider(category){
  // ===========Featured Owl Carousel============
  var objowlcarousel = $("."+category);
  console.log(objowlcarousel)
  if (objowlcarousel.length > 0) {
    objowlcarousel.owlCarousel({
      itemsMobile: [479, 2],
      responsive: {
        300: {
          items: 2,
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
          items: 5,
        },
        1200: {
          items: 5,
        },
      },
      lazyLoad: true,
      pagination: false,
      loop: true,
      dots: false,
      autoPlay: false,
      navigation: true,
      stopOnHover: true,
      nav: true,
      navigationText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"]
    });
  }else{
    setTimeout(() => { itemsSlider(category) }, 5000);
  }
}
function RightSideBar(){
  $('[data-toggle="offcanvas"]').on('click', function() {
    $('body').toggleClass('toggled');
  });
}
function LoadSliderJs() {
  MainSilder();
  // itemsSlider();

  //
  // ===========Category Owl Carousel============


  //
  // ===========Right Sidebar============
  $('[data-toggle="offcanvas"]').on('click', function() {
    $('body').toggleClass('toggled');
  });
  //
  //   // ===========Slider============
  //   var mainslider = $(".owl-carousel-slider");
  //   if (mainslider.length > 0) {
  //     mainslider.owlCarousel({
  //       items: 1,
  //       dots: false,
  //       lazyLoad: true,
  //       pagination: true,
  //       autoPlay: 4000,
  //       loop: true,
  //       singleItem: true,
  //       navigation: true,
  //       stopOnHover: true,
  //       nav: true,
  //       navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"]
  //     });
  //   }
  //
  //   // ===========Select2============
  //   $('select').select2();
  //
  //   // ===========Tooltip============
  //   $('[data-toggle="tooltip"]').tooltip()
  //
  //   // ===========Single Items Slider============
  //   var sync1 = $("#sync1");
  //   var sync2 = $("#sync2");
  //   sync1.owlCarousel({
  //     singleItem: true,
  //     items: 1,
  //     slideSpeed: 1000,
  //     pagination: false,
  //     navigation: true,
  //     autoPlay: 2500,
  //     dots: false,
  //     nav: true,
  //     navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
  //     afterAction: syncPosition,
  //     responsiveRefreshRate: 200,
  //   });
  //   sync2.owlCarousel({
  //     items: 5,
  //     navigation: true,
  //     dots: false,
  //     pagination: false,
  //     nav: true,
  //     navigationText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
  //     responsiveRefreshRate: 100,
  //     afterInit: function(el) {
  //       el.find(".owl-item").eq(0).addClass("synced");
  //     }
  //   });
  //
  //   function syncPosition(el) {
  //     var current = this.currentItem;
  //     $("#sync2")
  //       .find(".owl-item")
  //       .removeClass("synced")
  //       .eq(current)
  //       .addClass("synced")
  //     if ($("#sync2").data("owlCarousel") !== undefined) {
  //       center(current)
  //     }
  //   }
  //   $("#sync2").on("click", ".owl-item", function(e) {
  //     e.preventDefault();
  //     var number = $(this).data("owlItem");
  //     sync1.trigger("owl.goTo", number);
  //   });
  //
  //   function center(number) {
  //     var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
  //     var num = number;
  //     var found = false;
  //     for (var i in sync2visible) {
  //       if (num === sync2visible[i]) {
  //         var found = true;
  //       }
  //     }
  //     if (found === false) {
  //       if (num > sync2visible[sync2visible.length - 1]) {
  //         sync2.trigger("owl.goTo", num - sync2visible.length + 2)
  //       } else {
  //         if (num - 1 === -1) {
  //           num = 0;
  //         }
  //         sync2.trigger("owl.goTo", num);
  //       }
  //     } else if (num === sync2visible[sync2visible.length - 1]) {
  //       sync2.trigger("owl.goTo", sync2visible[1])
  //     } else if (num === sync2visible[0]) {
  //       sync2.trigger("owl.goTo", num - 1)
  //     }
  //   }
  // }else{
  //   setTimeout(() => { LoadSliderJs() }, 100);
  // }

}

$(document).ready(function() {
  "use strict";
})
