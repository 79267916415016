const getIndexOfCharacter = function(str, char) {
  let tmpArray = [...str]
  char = char.toLowerCase();
  return tmpArray.reduce((results, elem, index) =>
    elem.toLowerCase() === char ? [...results, index] : results, []
  )
}
String.prototype.$len = function (delimiter= null){
  if(delimiter == null) {
    return this.length;
  }
  return this.split(delimiter).length-1;
}

String.prototype.$piece = function(delimiter, pos, string) {
  if (string === undefined) {
    return this.split(delimiter)[pos - 1]
  }
  if (pos <= 0 || (string.trim() === "" || string == undefined)) return this
  const numberOfStars = this.split(delimiter).length - 1
  let currentStr = this
  if (numberOfStars < pos) {
    numberOfLake = pos - (numberOfStars) - 1
    var prepareString = delimiter.repeat(numberOfLake)
    currentStr = currentStr + prepareString
  }
  let results = getIndexOfCharacter(currentStr, delimiter)
  From = results[pos - 2] === undefined ? '' : currentStr.substr(0, results[pos - 2] + 1)
  To = results[pos - 1] === undefined ? '' : currentStr.substr(results[pos - 1], this.length)
  return From + string + To
}

Array.prototype.$remove = function (o) {
  const index = this.indexOf(o);
  if (index > -1) {
    this.splice(index, 1);
  }
  return this;
}
